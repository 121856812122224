import onefJef from '../assets/one-f Jef Logo.png';

export default () => {
	return (
		<div className="d-flex justify-content-center">
			<div>
				<div className="d-flex justify-content-center">
					&copy; 2000 to 2024 -
					<img src={onefJef} alt={`one-f Jef`} height={20} className="ms-1 me-2" style={{ marginTop: '2px' }} />
					All rights reserved.
				</div>
				<div style={{ fontSize: '9px' }}>
					one-f Jef is also known as Jef with one f.  Why?  Because how many f's does it take to make the f sound?
				</div>
			</div>
		</div>
	);
}
