import { useRef, FC } from "react"

interface Props {
  toggle: (t: string) => void;
  isChecked: (c: string) => boolean;
  title: string;
  unit: string;
}

export const AudioControl: FC<Props> = ({ toggle, isChecked, unit, title}) => {

  const audioRef = useRef<HTMLAudioElement | null>(null)
  const skipTime = 10

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(0, audioRef.current.currentTime - skipTime)
    }
  }

  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(audioRef.current.duration, audioRef.current.currentTime + skipTime)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }} key={title}>
      <input type="checkbox" id={title} onClick={() => toggle(title)} style={{ marginRight: '20px' }} checked={isChecked(title)} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {title}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <audio controls onError={() => alert('Error: Cannot load audio.')} ref={audioRef}>
            <source src={`/Hebrew/${unit}/${title} Hebrew.mp3`} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <button onClick={handleRewind}>&lt;</button>
          <button onClick={handleForward}>&gt;</button>
        </div>
      </div>
    </div>
  )
}