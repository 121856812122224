import { Route, Routes } from "react-router-dom"
import './App.css';
import AdonisErrorChecking from "./AdonisErrorChecking";
import Home from "./Home";
import DownloadHtml from "./domTest";
import DownloadHtmlFinished from "./domTestFinished";
import Hebrew from "./Hebrew";

function App() {
  const isMobile = window.innerWidth < 568

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/adonis" element={<AdonisErrorChecking />} />
      <Route path="/dom" element={<DownloadHtml />} />
      <Route path="/domDone" element={<DownloadHtmlFinished />} />
      <Route path="/hebrewAudio" element={<Hebrew />} />
    </Routes>
  );
}

export default App;

