import { Route, Routes } from "react-router-dom"
import logo from '../assets/one-f Jef Dev Logo.png';
import secretary from '../assets/Pencil-secretaryComputer.png';
import existingSystems from '../assets/Pencil-existingWebsite.png';
import newApp from '../assets/Pencil-mobileApp.png';
import './App.css';
import OneFJefFooter from '../components/OneFJefFooter';
import YouTube from '../assets/youtube.png'
import AdonisErrorChecking from "./AdonisErrorChecking";

function App() {
  const isMobile = window.innerWidth < 568

  return (
    <div className="container">
      <div className="text-center mb-2">
        <img src={logo} alt="logo" height={150} />
        <div className={isMobile ? 'text-start mb-2' : 'd-flex justify-content-center fs-4'}>
          one-f Jef loves to prove that software development should be fast, thorough and inexpensive (10% or less of the cost)! He has built worldwide, enterprise systems and is now available to serve the underserved on a short-term, in-and-out basis.
        </div>
        <div className={isMobile ? 'text-start mb-2' : 'd-flex justify-content-center s-5'}>
          However, no job is too small. one-f Jef will teach secretaries, other programmers, or build any business process fast from the ground up! What do you need?
        </div>
        contact: <a href="mailto: jef@1fjefdev.com" className="fs-5" style={{ textDecoration: "none", color: "maroon" }}>Jef@1fjefdev.com</a>
      </div>
      <div className="row justify-content-center me-3">
        <div className="col-12 col-sm-8 col-md-5 col-lg-4 mb-3 mx-auto">
          <div className="card" style={{ minWidth: '20rem' }}>
            <div className="card-header fs-3" style={{ backgroundColor: '#58c5f3'}}>
              Training
            </div>
            <div className="card-body">
              <img src={secretary} alt="secretary" className="img-fluid" />
            </div>
            <div className="card-footer">
              <div className="lh-sm mb-2 text-center">
                Free Trial:  <b>1 Hour</b>
                <div className="small opacity-50">No obligation</div>
              </div>
              <div className="text-start d-flex mb-2">
                <div className="me-2">&bull;</div>
                <div className="lh-sm">There are often little keyboard shortcuts that users will say, "I never knew that!"</div>
              </div>
              <div className="text-start d-flex mb-2">
                <div className="me-2">&bull;</div>
                <div className="lh-sm">one-f Jef can take an individual's productivity and work-intrigue to a new level.</div>
              </div>
              <div className="text-start d-flex mb-2">
                <div className="me-2">&bull;</div>
                <div className="lh-sm">If you have technical support employees, one-f Jef can pass on programming principles with React, RESTful api, and database efficiency.</div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-8 col-md-5 col-lg-4 mb-3 mx-auto">
          <div className="card" style={{ minWidth: '20rem' }}>
            <div className="card-header fs-3" style={{ backgroundColor: '#58c5f3' }}>
              Existing Systems
            </div>
            <div className="card-body">
              <img src={existingSystems} alt="existingSystems" className="img-fluid" />
            </div>
            <div className="card-footer">
              <div className="lh-sm mb-2 text-center">
                Free Trial:  <b>3 Hours</b>
                <div className="small opacity-50">No obligation</div>
              </div>
              <div className="text-start d-flex mb-2">
                <div className="me-2">&bull;</div>
                <div className="lh-sm">one-f Jef can learn your system quickly, suggest improvements or help enhance your current system.</div>
              </div>
              <div className="text-start d-flex mb-2">
                <div className="me-2">&bull;</div>
                <div className="lh-sm">Take three hours with one-f Jef to see if you like his style, conscious user interaction, and if you are ultimately surprised by his speed.</div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-8 col-md-5 col-lg-4 mb-3 mx-auto">
          <div className="card" style={{ minWidth: '20rem' }}>
            <div className="card-header fs-3" style={{ backgroundColor: '#58c5f3' }}>
              New Web App
            </div>
            <div className="card-body">
              <img src={newApp} alt="newApp" className="img-fluid" />
            </div>
            <div className="card-footer">
              <div className="lh-sm mb-2 text-center">
                Free Trial:  <b>3 Hours</b>
                <div className="small opacity-50">No obligation</div>
              </div>
              <div className="text-start d-flex mb-2">
                <div className="me-2">&bull;</div>
                <div className="lh-sm">one-f Jef specializes in database structures, business data process, and refining the interface for ease-of-use.</div>
              </div>
              <div className="text-start d-flex mb-2">
                <div className="me-2">&bull;</div>
                <div className="lh-sm">Let one-f Jef design your new internal or public-facing application to see if you are impressed with the thorough design and flexibility while you get to experience what can be done in 8 hours alone!</div>
              </div>
              {/* <div className="text-start d-flex mb-2">
                <div className="me-2">&bull;</div>
                <div className="lh-sm">See example applications below.</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div>
          <div className="text-left">
            Consider the savings and the short calendar days for delivery:
          </div>
          <div className="d-flex justify-content-center">
            <table cellSpacing="15px">
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Hourly cost</th>
                </tr>
              </thead>
              <tbody>
                <tr><td colSpan={2}><hr className="m-0 p-0" /></td></tr>
                <tr><td className="text-left me-2">Project Manager</td><td>$50</td></tr>
                <tr><td className="text-left me-2">Designer</td><td>$70</td></tr>
                <tr><td className="text-left me-2">Programmer</td><td>$70</td></tr>
                <tr><td className="text-left me-2">Programmer</td><td>$70</td></tr>
                <tr><td className="text-left me-2">Database engineer</td><td>$70</td></tr>
                <tr><td className="text-left me-2">Quality tester</td><td>$70</td></tr>
                <tr><td className="text-left me-2">HR & Accounting overhead&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td>$50</td></tr>
                <tr><td colSpan={2}><hr className="m-0 p-0" /></td></tr>
                <tr><td className="text-left me-2">Sub total</td><td>$450</td></tr>
                <tr><td className="text-left me-2">X 2 (half as fast)</td><td>&nbsp;&nbsp;X 2</td></tr>
                <tr><td colSpan={2}><hr className="m-0 p-0" /></td></tr>
                <tr><td className="text-left me-2">TOTAL</td><td><b>$900</b></td></tr>
                <tr><td></td></tr>
                <tr><td></td></tr>
                <tr><td></td></tr>
                <tr><td></td></tr>
                <tr><td></td></tr>
                <tr><td></td></tr>
                <tr><td className="text-left me-2"><b>or one-f Jef</b></td><td style={{ color: "#1f68b4" }}><b>$90</b></td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-5">
        <OneFJefFooter />
      </div>
      <div className="mx-auto" style={{maxWidth: '450px', width: 'auto'}}>
        <hr className="mt-0 mb-0" />
        <div className="fs-3 opacity-50">APPLICATION DEMOS</div>
        <hr className="mt-0"/>
        <div>
          <div className="fs-4 d-flex">Student Clubs</div>
          <div className="d-flex">
            <a href="https://youtu.be/NvAQG_bx_cE" target="_youtube" style={{textDecoration: "none"}}>
              <div className="ms-4" >
                <div className="ms-2">Clubs</div>
                <img src={YouTube} height={70} />
                <div className="ms-5">7:09</div>
              </div>
            </a>
          </div>
        </div>
        <hr className="mb-0" />
        <div>
          <div className="fs-4 d-flex">HR Goals and Evaluations <div className="fs-6 ms-3 mt-2">Staff and Faculty</div></div>
          <div className="d-flex">
            <a href="https://youtu.be/WkTSO2aIXcg" target="_youtube" style={{ textDecoration: "none" }}>
              <div className="ms-4" >
                <div className="ms-2">Admin</div>
                <img src={YouTube} height={70} />
                <div className="ms-5">5:08</div>
              </div>
            </a>
            <a href="https://youtu.be/cu0oVF8LHSI" target="_youtube" style={{ textDecoration: "none" }}>
              <div className="ms-4" >
                <div className="ms-2">Employee</div>
                <img src={YouTube} height={70} />
                <div className="ms-5">9:09</div>
              </div>
            </a>
            <a href="https://youtu.be/U47HDxQJU5A" target="_youtube" style={{ textDecoration: "none" }}>
              <div className="ms-4" >
                <div className="ms-2">Supervisor</div>
                <img src={YouTube} height={70} />
                <div className="ms-5">12:52</div>
              </div>
            </a>
          </div>
          <hr className="mb-0" />
          <div>
            <div className="fs-4 d-flex">FERPA <div className="fs-6 ms-3 mt-2">Family Educational Rights and Privacy Act</div></div>
            <div className="d-flex">
              <a href="https://youtu.be/HJ0o8IlObAU" target="_youtube" style={{ textDecoration: "none" }}>
                <div className="ms-4" >
                  <div className="ms-2">Admin</div>
                  <img src={YouTube} height={70} />
                  <div className="ms-5">3:23</div>
                </div>
              </a>
              <a href="https://youtu.be/_AJp9Oiif0s" target="_youtube" style={{ textDecoration: "none" }}>
                <div className="ms-4" >
                  <div className="ms-2">Facilitator</div>
                  <img src={YouTube} height={70} />
                  <div className="ms-5">4:31</div>
                </div>
              </a>
              <a href="https://youtu.be/rMNMewN-ahk" target="_youtube" style={{ textDecoration: "none" }}>
                <div className="ms-4" >
                  <div className="ms-2">Student</div>
                  <img src={YouTube} height={70} />
                  <div className="ms-5">5:08</div>
                </div>
              </a>
            </div>
            <hr className="mb-0" />
            <div>
              <div className="fs-4">Student Employee Scheduling</div>
              <div className="d-flex">
                <a href="https://youtu.be/LH_cthbklGw" target="_youtube" style={{ textDecoration: "none" }}>
                  <div className="ms-4" >
                    <div className="ms-2">Interactive Video Course Facilitators</div>
                    <img src={YouTube} height={70} />
                    <div className="ms-5">10:20</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

