import React from 'react';

const DownloadHtml: React.FC = () => {

  const downloadHtmlFile = () => {
    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">

      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Class Presentation</title>
        <link rel="stylesheet" href="styles.css">
      </head>

      <body>
        <header>
          <h1>The DOM</h1>
        </header>
        <nav>
          <ul>
            <li><div style="height: 40px">&nbsp;</div></li>
          </ul>
        </nav>
        <main>
          <section>
            <h2>Events</h2>
            <p>Click on the Events header above and then double click on this sentence.</p>
          </section>
          <hr/>
          <section>
            <h2>Input elements</h2>
            <p><input value="Some text here"></p>
            <p><input type="checkbox"><label>I want to know more.</label></p>
            <p>
              <input type="radio">1
              <input type="radio">2
              <input type="radio">3
              <input type="radio">4
            </p>
            <p><textarea>The textarea</textarea></p>
          </section>
          <hr />
          <section>
            <h2>Style</h2>
            <button>Change input style</button>
          </section>
          <hr />
          <section>
            <h2>Element Nodes</h2>
            <div><div>This is a hierarchy of elements.</div><span>A span here.</span><div>Another div here.</div></div>
            <br/>
            <button>Highlight the span</button>
          </section>
        </main>
      </body>
      </html>`;

    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'domTest.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const downloadCssFile = () => {
    const cssContent = `
      body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        background-color: #f4f4f4;
      }

      header {
        background-color: #4CAF50;
        color: white;
        text-align: center;
        padding: 1em 0;
      }

      nav {
        background-color: #333;
        overflow: hidden;
      }

      nav ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
      }

      nav ul li {
        margin: 0 15px;
      }

      nav ul li a {
        color: white;
        text-decoration: none;
        padding: 14px 20px;
        display: block;
      }

      nav ul li a:hover {
        background-color: #575757;
      }

      main {
        padding: 20px;
        max-width: 800px;
        margin: 0 auto;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }

      main section {
        margin-bottom: 20px;
      }`;

    const blob = new Blob([cssContent], { type: 'text/css' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'styles.css';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <br/>
      <br />
      <button className="btn btn-outline-warning ms-3" onClick={downloadHtmlFile}>Download HTML File</button>
      <br />
      <br />
      <br />
      <button className="btn btn-outline-info ms-3" onClick={downloadCssFile}>Download CSS File</button>
    </div>
  )
}

export default DownloadHtml;