import { useState } from "react"
import {AudioControl} from '../components/AudioControl'

const Hebrew = () => {
  const [idHidden, setIdHidden] = useState<string>();
  const [showAll, setShowAll ] = useState<boolean>(false);

  const isChecked = (id: string): boolean => {
    let list = localStorage.getItem("hiddenList");
    if (list) {
      let newList = list.split(',')
      return newList && newList.indexOf(id) > -1
    }
    return false
  }

  const toggle = (id: string) => {
    let newList: string[] = []
    let listString = localStorage.getItem("hiddenList");
    if (listString) {
      let list = listString.split(',')
      if (list.indexOf(id) > -1) {
        newList = list.filter(m => m !== id)
      } else {
        newList = list && list.length > 0 ? list.concat(id) : [id]
      }
    } else {
      newList = [id]
    }
    localStorage.setItem("hiddenList", newList.join(','));
    setIdHidden(id)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div onClick={() => setShowAll(!showAll)}>Show all</div>

      {(!isChecked('Unit 1-01') || showAll) && 
        <AudioControl unit={'Unit 1'} title={'Unit 1-01'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-02') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-02'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-03') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-03'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-04') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-04'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-05') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-05'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-06') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-06'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-07') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-07'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-08') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-08'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-09') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-09'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-10') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-10'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-11') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-11'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-12') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-12'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-13') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-13'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-14') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-14'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-15') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-15'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-16') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-16'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-17') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-17'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-18') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-18'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-19') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-19'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-20') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-20'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-21') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-21'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-22') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-22'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-23') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-23'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-24') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-24'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-25') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-25'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-26') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-26'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-27') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-27'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-28') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-28'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-29') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-29'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 1-30') || showAll) &&
        <AudioControl unit={'Unit 1'} title={'Unit 1-30'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-01') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-01'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-02') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-02'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-03') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-03'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-04') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-04'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-05') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-05'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-06') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-06'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-07') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-07'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-08') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-08'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-09') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-09'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-10') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-10'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-11') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-11'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-12') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-12'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-13') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-13'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-14') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-14'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-15') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-15'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-16') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-16'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-17') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-17'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-18') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-18'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-19') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-19'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-20') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-20'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-21') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-21'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-22') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-22'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-23') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-23'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-24') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-24'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-25') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-25'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-26') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-26'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-27') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-27'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-28') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-28'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-29') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-29'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 2-30') || showAll) &&
        <AudioControl unit={'Unit 2'} title={'Unit 2-30'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-01') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-01'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-02') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-02'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-03') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-03'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-04') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-04'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-05') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-05'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-06') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-06'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-07') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-07'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-08') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-08'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-09') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-09'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-10') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-10'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-11') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-11'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-12') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-12'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-13') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-13'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-14') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-14'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-15') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-15'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-16') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-16'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-17') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-17'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-18') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-18'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-19') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-19'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-20') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-20'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-21') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-21'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-22') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-22'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-23') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-23'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-24') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-24'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-25') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-25'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-26') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-26'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-27') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-27'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-28') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-28'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-29') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-29'} toggle={toggle} isChecked={isChecked} />
      }
      {(!isChecked('Unit 3-30') || showAll) &&
        <AudioControl unit={'Unit 3'} title={'Unit 3-30'} toggle={toggle} isChecked={isChecked} />
      }
      <a href="/Hebrew.zip" download="hebrew.zip">
        Download ZIP
      </a>      
    </div>
  )
}

export default Hebrew
